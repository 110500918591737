import React from 'react'

const Pasos = () => {
  return (
    <div
    className="alert alert-success alertpasos"
    role="alert"
    style={{
      width: "76%",
      padding: "15px",
      marginTop: "10px",
    }}
  >
    <h2
      style={{ fontSize: "15px", fontWeight: "500", paddingTop: "5px" }}
    >
      {" "}
      PASOS PARA REGISTRO:{" "}
    </h2>
    <li>Click en el botón Tomar Foto</li>
    <li>Confirmas tu foto </li>
    <li>Registras tu Entrada o Salida</li>
  </div>
  )
}

export default Pasos