import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
const getPasswordStrength = (password) => {
  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

  const strength = (hasUppercase + hasLowercase + hasNumber + hasSpecialChar) * 25;

  const lengthBonus = Math.min(Math.floor(password.length / minLength), 3) * 25;

  // calcular el total de seguridad
  const totalStrength = Math.min(strength + lengthBonus, 100);

  return totalStrength;
};

function CambiarPass() {
  const [nuevaContraseña, setNuevaContraseña] = useState('');
  const [dniLogin, setDniLogin] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);


  const fetchCambiarPass = async () => {
    // Validate new password
    if (!nuevaContraseña) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'La nueva contraseña no puede estar vacía.',
      });
      return;
    }
  
    // Regla para validar que tenga mayusculas,minisculas y numero
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/;
    if (!passwordRegex.test(nuevaContraseña)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          'La nueva contraseña debe contener al menos una mayúscula, una minúscula y un número.',
      });
      return;
    }
  
    const datoactualizar = {
      SERE_chCLAVE: nuevaContraseña,
    };
  
    try {
      const response = await axios.put(
        `https://api01.cloudlab.net.pe/pi126_M_MAES_SEREN_p00/?sere_p_chdni=${dniLogin}/`,
        datoactualizar,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Estado Actualizado Correctamente',
          text: 'La contraseña se actualizó correctamente',
        }).then(() => {
          window.location.reload('/');
        });
      }
    } catch (error) {
      console.error('Error al actualizar Estado:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al Atender',
        text: 'La contraseña no se actualizó correctamente.',
      });
    }
  };
  const regresarCambiarPass = () => {
    window.location.href = "/";
  };
  useEffect(() => {
    const datos = JSON.parse(localStorage.getItem('datos'));

    if (datos) {
      const { dnilogin } = datos;

      setDniLogin(dnilogin);

    }
  }, []);

  const handleNuevaContraseñaChange = (event) => {
    const newPassword = event.target.value;
    setNuevaContraseña(newPassword);
    const strength = getPasswordStrength(newPassword);
    setPasswordStrength(strength);
  };


    const strength = getPasswordStrength(nuevaContraseña);

  return (
    <div className='CambiarPass-Container' style={{padding:'10%',width:'60%',margin:'auto',display:'flex',flexDirection:'column',marginTop:'5%'}}>
      
      <div className="card" style={{width:'100%',height:'100%',transition:'box-shadow .3s ease, transform .2s ease',padding:'5px',borderRadius:'10px'}}>
      <h2 style={{textAlign:'center',padding:'10px 0',background:'rgb(38, 148, 115)',color:'white',width:'100%'}}>Cambio de Contraseña</h2>
      <div class="alert alert-warning" role="alert" style={{fontSize:'13px', textAlign:'center'}}>
        La Contraseña debe contener Mayusculas, Minusculas y Número/os
      </div>
      <div className="mb-3  text-center">
        <label className="form-label">Nueva Contraseña</label>

        <div class="input-group">
          <input type="password" 
          className={`form-control ${passwordStrength >= 75 ? 'is-valid' : 'is-invalid'}`}
          value={nuevaContraseña}
          onChange={handleNuevaContraseñaChange}
          />
          <span class="input-group-text bg-success"><i class="fa-solid fa-lock" style={{color:'white'}}></i></span>
        </div>
      </div>
      <div className="progress">
        
          <div
            className={`progress-bar progress-bar-striped ${
              passwordStrength >= 75 ? 'bg-success' : 'bg-danger'
            }`}
            role="progressbar"
            style={{ width: `${passwordStrength}%` }}
            aria-valuenow={passwordStrength}
            aria-valuemin="0"
            aria-valuemax="100"
          ><strong>{passwordStrength}%</strong></div>
        </div>
        <div className="botonesCambiarpass" style={{display:'flex',gap:'10px',width:'100%',justifyContent:'center',marginTop:'10px'}}>
        <button
          className="btn btn-danger"
          style={{width:'100%' }}
          onClick={regresarCambiarPass}
        >
          Regresar<i class="fa-solid fa-person-walking-arrow-loop-left"></i>
        </button>
        <button
          className="btn btn-success"
          style={{width:'100%' }}
          onClick={fetchCambiarPass}
        >
          Cambiar Contraseña<i class="fa-solid fa-unlock-keyhole"></i>
        </button>
        </div>

      </div>
      
  </div>
  )
}

export default CambiarPass