import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import "../../Componentes/CSS/App.css";
import { Button, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import API_ASISTENCIA from "../../Componentes/config/apisAsistencia_Variables";
import Pasos from "./Componentes/Pasos";
import DatosxAdmitidos from "./Componentes/DatosxAdmitidos";
import DatosxUsuario from "./Componentes/DatosxUsuario";
import CamaraFuncion from "./Componentes/CamaraFuncion";

const fecha = new Date();
const dia = fecha.getDate();
const mes = fecha.getMonth() + 1;
const año = fecha.getFullYear().toString().slice(-2);
function FotoCamera() {
  //Declaración de const
  const rol = Cookies.get("rol");
  const dnilogin = Cookies.get("dnilogin");
  const nombres = Cookies.get("nombre");
  const apellidopat = Cookies.get("apellidopat");
  const apellidomat = Cookies.get("apellidomat");
  const [serenos, setSerenos] = useState([]);
  const [documento, setDocumento] = useState(dnilogin);
  const [errorMessage, setErrorMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [datos, setDatos] = useState(null);
  //Const que maneja el estado para abrir y cerrar el modal de Foto
  const [showModalPhoto, setShowModalPhoto] = useState(false);
  const [address, setAddress] = useState("");
  //console.log(address);
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [cameraOn, setCameraOn] = useState(true);
  const [photoAceptada, setPhotoAceptada] = useState(false);
  const [dnivalido, setDnivalido] = useState(false);
  const [selectedFuncion, setSelectedFuncion] = useState("");
  console.log(documento)
  console.log(selectedFuncion);
  const videoRef = useRef();
  let streamRef = useRef(null);

  //Funcion para insertar la marca de agua a la foto
  const addWatermark = (imageSrc, dateTime) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      ctx.font = "bold 15px Arial";
      ctx.textAlign = "right";
      const margin = 10;
      const lineHeight = 20; // Espacio entre líneas

      const textDate = `CON ${dateTime} `;
      const textUser = `${datos && datos.SERE_chNOM ? datos.SERE_chNOM : ""} ${
        datos && datos.SERE_chAPEPAT ? datos.SERE_chAPEPAT : ""
      } ${datos && datos.SERE_chAPEMAT ? datos.SERE_chAPEMAT : ""}`;
      const addressComponents = address.split(",").slice(0, 2).join(", ");
      const textAdress = `${addressComponents}, Lima`;
      const x = canvas.width - margin;
      let y = canvas.height - margin;
      const maskedDNI = `${documento.substring(0, 3)}*****`;
      const textDNI = `DNI: ${maskedDNI}`;
      const Cargo = `${selectedFuncion}`;
      // Función para dibujar texto con borde
      const drawTextWithBorder = (text, x, y) => {
        ctx.fillStyle = "#000"; // Color del borde (blanco)
        ctx.lineWidth = 4; // Ancho del borde
        ctx.strokeText(text, x, y); // Dibujar borde
        ctx.fillStyle = "#fff"; // Color del texto (negro)
        ctx.fillText(text, x, y); // Dibujar texto
      };

      // Dibujar cada línea de texto desde la parte inferior hacia arriba
      drawTextWithBorder(textAdress, x, y);
      y -= lineHeight;
      drawTextWithBorder(textDate, x, y);
      y -= lineHeight;
  
      drawTextWithBorder(Cargo, x, y);
      y -= lineHeight;
      drawTextWithBorder(textUser, x, y);
      y -= lineHeight;
      drawTextWithBorder(textDNI, x, y);
      y -= lineHeight;

      const imageWithWatermarkURL = canvas.toDataURL("image/jpeg");
      setPhotoURL(imageWithWatermarkURL);
    };
  };
  //Funcion para tomar la foto
  const takePhoto = async () => {
    try {
      const video = videoRef.current;
      if (video) {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        const photoURL = canvas.toDataURL("image/jpeg");
        const now = new Date();
        const dateTime = now.toLocaleString();
        addWatermark(photoURL, dateTime);
        setShowModalPhoto(true);
      } else {
        console.error("Video ref is not initialized.");
      }
    } catch (error) {
      console.error("Error al capturar la foto:", error);
    }
  };

  //Función para recargar la camara
  const handleToggleCamera = () => {
    if (!documento) {
      Swal.fire({
        icon: "error",
        title: "Error al cargar cámara",
        text: "Debe ingresar un DNI",
      });
      return;
    }
    if (!dnivalido) {
      Swal.fire({
        icon: "error",
        title: "Error al cargar cámara",
        text: "El dni ingresado no está registrado",
      });
      return;
    }
    if (!selectedFuncion) {
      Swal.fire({
        icon: "error",
        title: "Error al cargar cámara",
        text: "Primero debe seleccionar su Actividad",
      });
      return;
    }
    setCameraOn((prevState) => !prevState);
  };
  //Funcion para limpiar la foto
  const handlelimpiarfoto = async () => {
    //Valida que haya una foto guardada
    if (!photoURL) {
      Swal.fire({
        icon: "error",
        title: "Error al limpiar",
        text: "Aún no hay una foto para limpiar",
      });
      return;
    }
    //Limpia la foto guardada y recarga la camara
    setPhotoURL("");
    setPhotoAceptada("");
    handleToggleCamera();
  };
  //Funcion para cambiar la camara , registra las camaras que contiene el dispositivo y las lista , luego cambia una a otra
  const handleCambiarCamara = async () => {
    if (!documento) {
      Swal.fire({
        icon: "error",
        title: "Error al cambiar cámara",
        text: "Debe ingresar un DNI",
      });
      return;
    }
    if (!dnivalido) {
      Swal.fire({
        icon: "error",
        title: "Error al cambiar cámara",
        text: "El dni ingresado no está registrado",
      });
      return;
    }
    if (!selectedFuncion) {
      Swal.fire({
        icon: "error",
        title: "Error al cargar cámara",
        text: "Primero debe seleccionar su Actividad",
      });
      return;
    }
    try {
      const videoStream = videoRef.current.srcObject;
      const tracks = videoStream.getTracks();
      tracks.forEach((track) => track.stop());
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      let nextCameraIndex = 0;
      videoDevices.forEach((device, index) => {
        if (device.deviceId === tracks[0].getSettings().deviceId) {
          nextCameraIndex = (index + 1) % videoDevices.length;
        }
      });
      const nextCameraStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: videoDevices[nextCameraIndex].deviceId },
        },
      });
      videoRef.current.srcObject = nextCameraStream;
    } catch (error) {
      console.error("Error al cambiar la cámara:", error);
    }
  };
  //funcion que enciende la cámara
  useEffect(() => {
    const enableCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoRef.current.srcObject = stream;
      } catch (error) {
        console.error("Error al acceder a la cámara:", error);
        setCameraOn(true);
      }
    };
    if (cameraOn) {
      enableCamera();
    }
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
    };
  }, [videoRef, cameraOn]);

  //Renderiza la funcion de ubicación para que se ejecute al iniciar la pagina
  useEffect(() => {
    //Obtener la ubicaciòn de googlemaps
    const fetchAddress = async (latitude, longitude) => {
      const apiKey = "a1100a44b44349b6a3bb16186a5e5a3d";
      const apiUrl = ` https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const formattedAddress = data.results[0].formatted;
          setAddress(formattedAddress);
        } else {
          setAddress("Address not found");
        }
      } catch (error) {
        console.error("Error fetching address:", error);
        setAddress("Error fetching address");
      }
    };
    //Función para obtener la ubicación
    const obtenerUbicacion = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLatitud(latitude);
            setLongitud(longitude);
            fetchAddress(latitude, longitude);
          },
          (error) => {
            console.error("Error al obtener la ubicación:", error);
          }
        );
      } else {
        console.error(
          "La geolocalización no es compatible con este navegador."
        );
      }
    };
    obtenerUbicacion();
    //Obtener los datos del sereno consultado
    const obtenerDatos = async () => {
      try {
        const respuesta = await axios.get(
          `https://api01.cloudlab.net.pe/pi121_M_MAES_SEREN_DNIQR/${documento}/1/`
        ); //Enviamos los datos obtenidos en la respuesta, enviamos a la const Datos(nos almacena el array con los datos)
        setDatos(respuesta.data);
        setSelectedFuncion(respuesta.data.SERE_chCargo);
        if (respuesta.data && Object.keys(respuesta.data).length > 0) {
          setDnivalido(true);
        } else {
          setDnivalido(false);
        }
      } catch (error) {
        setDnivalido(false);
      }
    };
    if (documento) {
      obtenerDatos();
    }
  }, [documento, dnivalido]);
  //Función que inicia la camara al darle al boton de cámara
  useEffect(() => {
    if (!cameraOn) {
      reloadCamera();
    }
  }, [cameraOn]);
  //Función para cerrar el modal de foto
  const handleClosePhoto = () => {
    setShowModalPhoto(false);
    stopVideoStream();
  };
  //Función del boton aceptar , guarda la foto y cambia el estado de foto aceptada que luego se utiliza para mostrar la foto en pantalla al darle aceptar
  const handleAceptPhoto = () => {
    if (!photoURL) {
      Swal.fire({
        icon: "error",
        title: "Error al Aceptar la Foto",
        text: "Vuelva a tomar su foto, tener en cuenta dar permisos a la cámara y recargar la cámara",
      });
      return;
    }
    setShowModalPhoto(false);
    stopVideoStream();
    setPhotoAceptada(true);
  };
  //Funcion para el boton de tomar foto , abre el modal y toma la foto
  const handleTomarPhoto = () => {
    if (!documento) {
      Swal.fire({
        icon: "error",
        title: "Error al tomar la foto",
        text: "Debe ingresar un DNI",
      });
      return;
    }
    if (!dnivalido) {
      Swal.fire({
        icon: "error",
        title: "Error al tomar la foto",
        text: "El dni ingresado no está registrado",
      });
      return;
    }
    if (!selectedFuncion) {
      Swal.fire({
        icon: "error",
        title: "Error al tomar foto",
        text: "Primero debe seleccionar su Actividad",
      });
      return;
    }
    if (!longitud && !latitud) {
      Swal.fire({
        icon: "error",
        title: "Active su ubicación",
        text: "Active su ubicación y vuelva a cargar la página.",
      });
    }

    setShowModalPhoto(true);
    takePhoto();
  };
  //Funcion para detener la camara al abrir el modal de foto
  const stopVideoStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  };
  const reloadCamera = () => {
    stopVideoStream();
    setCameraOn(true);
    setPhotoAceptada(false);
    setPhotoURL("");
  };
  //Funcion para subir la foto con la solicitud POST
  const handleUpload = async (event, tipoRegistro) => {
    event.preventDefault();
    try {
      // Validaciones
      if (!photoAceptada || !photoURL) {
        Swal.fire({
          icon: "error",
          title: "Error al subir",
          text: "Debe capturar una foto.",
        });
        return;
      }
      if (!documento) {
        Swal.fire({
          icon: "error",
          title: "Error al subir",
          text: "Debe elegir un usuario.",
        });
        return;
      }
      if (!dnivalido) {
        Swal.fire({
          icon: "error",
          title: "Error al subir",
          text: "El dni ingresado no está registrado.",
        });
        return;
      }
      if (!selectedFuncion) {
        Swal.fire({
          icon: "error",
          title: "Error al cargar cámara",
          text: "Primero debe seleccionar su Actividad",
        });
        return;
      }
      if (!longitud && !latitud) {
        Swal.fire({
          icon: "error",
          title: "Active su ubicación",
          text: "Active su ubicación y vuelva a cargar la página.",
        });
        return;
      }
      // Preparar datos para registrar asistencia
      const dniregistrante = Cookies.get("dnilogin");
      const horaActual = new Date();
      const horaConsultaFormato = horaActual.toLocaleTimeString();
      const datosAsistencia = {
        sere_p_chdni: documento,
        asis_chhor: horaConsultaFormato,
        asis_chentsal: tipoRegistro,
        asis_choapp: "appwebasis",
        asis_chfun: selectedFuncion,
        asis_chtipreg: "Asis",
        asis_chdesact: "",
        Cata_CH_Codcatarec: "",
        ASIS_CH_TIPO_BI: "I",
        cata_CH_Cod_rolserv: "",
        cata_IN_IDPK_rolserv: null,
        ASIS_IN_idpk_respondoc: dniregistrante,
        cata_CH_IDPK_rolservsr: "",
        asis_in_lat: latitud,
        asis_in_lon: longitud,
        asis_ch_mapdir:address
      };
      // Enviar los datos de asistencia primero
      const responseAsistencia = await axios.post(
        API_ASISTENCIA["postasistencia"],
        datosAsistencia
      );
      if (responseAsistencia.status >= 200 && responseAsistencia.status < 300) {
        const valor = responseAsistencia.data.asis_p_inid;
        const fechahoy = `${dia < 10 ? "0" + dia : dia}${
          mes < 10 ? "0" + mes : mes
        }${año}`; // Preparar datos para la subida de la imagen
        const formData = new FormData();
        const blob = await fetch(photoURL).then((res) => res.blob());
        formData.append(
          "file1",
          blob,
          `A${fechahoy}_${documento}-${selectedFuncion}.jpg`
        );
        // Enviar la foto
        const responsePhoto = await axios.post(
          API_ASISTENCIA["enviarfotoevidencia"](
            documento,
            dnilogin,
            latitud,
            longitud,
            valor
          ),
          formData
        );
        if (responsePhoto.status >= 200 && responsePhoto.status < 300) {
          Swal.fire({
            icon: "success",
            title: "Subida Exitosa",
            text: "La imagen se subió y la asistencia se registró correctamente.",
          }).then(() => {
            setPhotoURL("");
            handleToggleCamera();
          });
        } else {
          throw new Error("Fallo en la subida de la imagen");
        }
      } else {
        throw new Error("Fallo en el registro de asistencia");
      }
    } catch (error) {
      console.error("Error al registrar asistencia o subir el archivo:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error al procesar la solicitud. Por favor intente de nuevo.",
      });
    }
  };
  useEffect(() => {
    //Funcion para obtener los usuarios
    const fetchUsuarios = async () => {
      try {
        const response = await axios.get(
          "https://api01.cloudlab.net.pe/pi120_M_MAES_SEREN_LIST/?skip=0&limit=1000"
        );
        //Se actualiza el estado de la const Serenos
        setSerenos(response.data);
      } catch (error) {
        setErrorMessage("Error de Servidor para obtener los usuarios");
        setShowToast(true);
      }
    };
    fetchUsuarios();
  }, []);
  const handleConsultas = () => {
    window.location.href = "https://t.me/+HmrUJPNO2mpmMmE5";
  };
  const handleCambiarPass = () => {
    window.location.href = "/Cambiar-pass";
  };
  const handleLogout = () => {
    Cookies.remove("rol");
    Cookies.remove("nombre");
    Cookies.remove("apellidopat");
    Cookies.remove("apellidomat");
    Cookies.remove("dnilogin");
    Cookies.remove("miip");
    Cookies.remove("codigorol");
    Cookies.remove("idrol");
    Cookies.remove("horario");
    window.location.replace("/");
  };
  return (
    <div className="app">
      <h2
        style={{
          backgroundColor: "#025E73",
          color: "white",
          padding: "15px",
          textAlign: "center",
        }}
      >
        <img
          src={require("../../Componentes/Imagenes/icono.png")}
          alt="Descripción de la imagen"
          style={{ marginRight: "10px", width: "40px", height: "40px" }}
        />
        Registro de Control
      </h2>
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-success"
          style={{ marginLeft: "10px" }}
          onClick={handleConsultas}
        >
          Consultas y Dudas <i className="fa-solid fa-headset"></i>
        </button>
        <button
          className="btn btn-warning"
          style={{ marginLeft: "10px",marginRight:'10px' }}
          onClick={handleCambiarPass}
        >
          Cambiar Contraseña <i class="fa-solid fa-unlock-keyhole"></i>
        </button>
        <button
          className="btn btn-danger"
          style={{ marginRight: "10px" }}
          onClick={handleLogout}
        >
          Cerrar Sesión <i className="fa-solid fa-right-from-bracket"></i>
        </button>
      </div>
      {showToast && (
        <div
          className="position-fixed bottom-0 end-0 p-3"
          style={{ zIndex: 11 }}
        >
          <div
            className="toast show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header bg-primary text-white">
              <strong className="me-auto">Mensaje del Sistema</strong>
              <small>Justo Ahora</small>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowToast(false)}
                aria-label="Close"
              ></button>
            </div>
            <div className="toast-body">{errorMessage}</div>
          </div>
        </div>
      )}
      <div className="row divweb" style={{ marginTop: "50px" }}>
        <div
          className="col-xl-6 col-lg-6 col-sm-12"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Pasos />{" "}
          {rol === "00-ADMIN" ||
          rol === "04-OPERADOR" ||
          rol === "05-SUPERVISOR-AXF" ? (
            <DatosxAdmitidos
              serenos={serenos}
              setDocumento={setDocumento}
              setSelectedFuncion={setSelectedFuncion}
              setDatos={setDatos}
              setDnivalido ={setDnivalido}
              documento={documento}
              dnivalido={dnivalido}
              datos={datos}
            />
          ) : (
            <DatosxUsuario
              nombres={nombres}
              apellidopat={apellidopat}
              apellidomat={apellidomat}
              selectedFuncion={selectedFuncion}
              documento={documento}
              setSelectedFuncion={setSelectedFuncion}
            />
          )}
        </div>
        <CamaraFuncion
          cameraOn={cameraOn}
          photoAceptada={photoAceptada}
          photoURL={photoURL}
          videoRef={videoRef}
          handlelimpiarfoto={handlelimpiarfoto}
          handleToggleCamera={handleToggleCamera}
          handleCambiarCamara={handleCambiarCamara}
          handleUpload={handleUpload}
          handleTomarPhoto={handleTomarPhoto}
        />
      </div>
      <Modal
        show={showModalPhoto}
        onHide={handleClosePhoto}
        className="custom-modal"
        centered
      >
        <Modal.Body className="datos">
          {photoURL && (
            <img
              src={photoURL}
              alt="Captured"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            href={photoURL}
            variant="warning"
            download="Captured"
            className="funcionmodal"
          >
            Descargar
          </Button>
          <Button
            variant="success"
            onClick={handleAceptPhoto}
            className="funcionmodal"
          >
            Confirmar Foto
          </Button>
          <Button
            variant="danger"
            onClick={handleClosePhoto}
            className="funcionmodal"
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FotoCamera;
