import React from 'react'

const Botonera = ({photoAceptada ,handlelimpiarfoto,handleToggleCamera,handleCambiarCamara ,handleUpload,handleTomarPhoto}) => {
  return (
    <div
    className="divbotonesbajocamara"
    style={{
      width: "100%",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    }}
  >
    <div
      className="divbotonesbajocamara"
      style={{
        display: "flex",
        width: "80%",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {photoAceptada ? (
        <button
          className="btn btn-danger botonlimpiarfoto"
          onClick={handlelimpiarfoto}
          title="Subir foto"
          style={{ width: "100%", height: "50px", borderRadius: "2px" }}
        >
          Limpiar Foto <i className="fa-solid fa-trash-can"></i>
        </button>
      ) : (
        <>
          <button
            className="btn btn-primary cargarcamara"
            onClick={handleToggleCamera}
            title="Recargar Cámara"
            style={{
              width: "100%",
              height: "50px",
              borderRadius: "2px",
            }}
          >
            Activar Cámara <i className="fa-solid fa-camera"></i>
          </button>
          <button
            className="btn btn-warning botoncambiarcamara"
            onClick={handleCambiarCamara}
            title="Cambiar Cámara"
            style={{ display: "none" }}
          >
            Cambiar Cámara <i className="fa-solid fa-camera-rotate"></i>
          </button>
        </>
      )}
    </div>
    <div
      className="divbotonesbajocamara"
      style={{
        display: "flex",
        width: "80%",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {photoAceptada ? (
        <>
          <button
            className="btn botonsubirfoto"
            onClick={(event) => handleUpload(event, "AE")}
            title="Subir foto"
            style={{
              width: "50%",
              height: "50px",
              borderRadius: "2px",
              backgroundColor: "#76FF7A",
              color: "black",
            }}
          >
            REGISTRA ENTRADA <i className="fa-solid fa-floppy-disk"></i>
          </button>
          <button
            className="btn btn-primary botonsubirfoto"
            onClick={(event) => handleUpload(event, "AS")}
            title="Subir foto"
            style={{
              width: "50%",
              height: "50px",
              borderRadius: "2px",
            }}
          >
            REGISTRA SALIDA <i className="fa-solid fa-floppy-disk"></i>
          </button>
        </>
      ) : (
        <button
          className="btn btn-dark botontomarfoto"
          onClick={handleTomarPhoto}
          title="Tomar Foto"
          style={{
            width: "100%",
            height: "50px",
            borderRadius: "2px",
            color: "white",
          }}
        >
          Tomar Foto <i className="fa-solid fa-photo-film"></i>
        </button>
      )}
    </div>
  </div>
  )
}

export default Botonera