import {Navigate, Route, Routes} from 'react-router-dom'
import { RoutesPrivate } from './ProtectedRoute'
import FotoCamera from '../Pages/Asistencia/FotoCamera'
import CambiarPass from '../Pages/Asistencia/Componentes/CambiarPass'





const PrivateRoutes = () => {


  return (
    <Routes>
        <Route path='/*' element={<Navigate to={RoutesPrivate.DASHBOARD} />} />
        <Route path={RoutesPrivate.DASHBOARD} element={<FotoCamera />} />
        <Route path={RoutesPrivate.CAMBIARPASS} element={<CambiarPass />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}
export {PrivateRoutes}
