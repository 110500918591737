import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import Select from "react-select";
import CryptoJS from "crypto-js";

const DatosQR = ({
  showModalDatosQr,
  onclose,
  setDocumento,
  datos,
  setDatos,
  setSelectedFuncion,
  setDnivalido,
  dnivalido,
  documento
}) => {
  const [result, setResult] = useState("");
  console.log(result);
  const clave = "fPbW6TFfBwScdx0kJ4HFjZJ4MPGphp2j";

  const desencriptarDato = (datoEncriptado) => {
    try {
      // Parsear la clave a bytes
      const keyBytes = CryptoJS.enc.Utf8.parse(clave);

      // Decodificar el dato encriptado desde Base64
      const encryptedData = CryptoJS.enc.Base64.parse(datoEncriptado);

      // Realizar la desencriptación
      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedData },
        keyBytes,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      // Convertir el resultado a texto UTF-8
      const datoDesencriptado = decrypted.toString(CryptoJS.enc.Utf8);

      console.log("Dato desencriptado:", datoDesencriptado);
      return datoDesencriptado;
    } catch (error) {
      console.error("Error desencriptando dato:", error);
      return null;
    }
  };

  const handleScan = (data) => {
    if (data) {
      console.log("Datos Escaneados:", data);
      setResult(data);
    }
  };

  const handleError = (err) => {
    console.error("Error al acceder a la cámara:", err);
  };

  const handleResult = (result) => {
    if (result) {
      console.log("Resultado del escaneo:", result);
      setResult(result);
      Cookies.set("codigo", result);
    }
  };

  const obtenerDatos = async () => {
    try {
      const resultStr = String(result);
      const dniNumber = desencriptarDato(resultStr);
      console.log(dniNumber);
      const respuesta = await axios.get(
        `https://api01.cloudlab.net.pe/pi121_M_MAES_SEREN_DNIQR/${
          result ? dniNumber : documento
        }/1/`
      );

      // Verificar si la respuesta contiene datos válidos
      if (respuesta.data && Object.keys(respuesta.data).length > 0) {
        setDocumento(dniNumber);
        setDatos(respuesta.data);
        setSelectedFuncion(respuesta.data.SERE_chCargo);
        setDnivalido(true);
      } else {
        setDatos({});
        setSelectedFuncion("");
        setDnivalido(false);
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      setDatos({});
      setSelectedFuncion("");
      setDnivalido(false);
    }
  };

  useEffect(() => {
    // Llamar a obtenerDatos solo si result tiene un valor (el número del DNI)
    if (result) {
      obtenerDatos();
    }
  }, [result]);

  const qrReaderStyle = {
    width: "100%",
    objectFit: "cover",
    borderRadius: "8px",
  };

  return (
    <Modal
      show={showModalDatosQr}
      onHide={onclose}
      className="custom-modal"
      centered
    >
      <Modal.Body className="datos">
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          onResult={handleResult}
          style={qrReaderStyle}
        />
        <div
          className="divdatosdniapellidos"
          style={{ display: "flex", gap: "10px", width: "100%" }}
        >
          <input
            type="text"
            value={`${datos?.SERE_chNOM ?? ""} ${datos?.SERE_chAPEPAT ?? ""} ${
              datos?.SERE_chAPEMAT ?? ""
            }`}
            id="placaInput"
            className="form-control"
            style={{
              border: "1px solid rgb(53, 53, 166)",
              textAlign: "center",
              width: "100%",
              fontSize: "15px",
              borderRadius: "2px",
              whiteSpace: "pre-wrap",
              overflow: "hidden",
              resize: "none",
              height: "auto",
              padding: "5px",
            }}
            disabled
          />
          <input
            type="text"
            value={datos?.SERE_P_chDNI ?? ""}
            className="form-control"
            id="placaInput2"
            style={{
              border: "1px solid rgb(53, 53, 166)",
              textAlign: "center",
              width: "100%",
              fontSize: "15px",
              borderRadius: "2px",
            }}
            disabled
          />
        </div>
        <div
          className="mb-3 divdatosdniapellidos"
          style={{
            width: "100%",
            padding: "10px 0",
          }}
        >
          <input
            type="text"
            value={datos?.SERE_chCargo ?? ""}
            id="placaInput"
            className="form-control"
            style={{
              border: "1px solid rgb(53, 53, 166)",
              textAlign: "center",
              width: "100%",
              fontSize: "15px",
              borderRadius: "2px",
            }}
            disabled
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={onclose} className="funcionmodal">
         Registrar Control
        </Button>
        <Button variant="danger" onClick={onclose} className="funcionmodal">
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DatosQR;
