import React from "react";
import Botonera from "./Botonera";

const CamaraFuncion = ({
  cameraOn,
  photoAceptada,
  photoURL,
  videoRef,
  handlelimpiarfoto,
  handleToggleCamera,
  handleCambiarCamara,
  handleUpload,
  handleTomarPhoto,
}) => {
  return (
    <div
      className="col-xl-6 col-lg-6 col-sm-12"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        className="camaraconcainer"
        style={{
          width: "100%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div style={{ maxHeight: "500px" }}>
          {cameraOn &&
            (photoAceptada && photoURL ? (
              <img
                src={photoURL}
                alt="Captured"
                style={{
                  width: "100%",
                  maxWidth: "800px",
                  maxHeight: "400px",
                  objectFit: "cover"
                }}
              />
            ) : (
<video
      ref={videoRef}
      autoPlay
      style={{
        width: "100%",
        maxWidth: "800px",
        maxHeight: "400px",
        objectFit: "cover"
      }}
    />
            ))}
        </div>
      </div>
      <Botonera
        photoAceptada={photoAceptada}
        handlelimpiarfoto={handlelimpiarfoto}
        handleToggleCamera={handleToggleCamera}
        handleCambiarCamara={handleCambiarCamara}
        handleUpload={handleUpload}
        handleTomarPhoto={handleTomarPhoto}
      />
    </div>
  );
};

export default CamaraFuncion;
